<template>
  <div class="footer bg-dark pt-8" id="bottom">
    <!-- footer -->
    <div class="container">
      <div class="row">
        <div class="col-xl-4 col-lg-4 col-sm-12 col-12">
          <div class="mb-4 mb-lg-0">
            <!-- Footer Logo -->
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1092.22 207.66" width="220" height="100">

              <g id="Calque_2" data-name="Calque 2">
                <g id="COMP_02" data-name="COMP 02">
                  <path class="cls-1"
                    d="M216,207.66c-10.67,0-14.94,0-22.95-6.94l-11-9.61C171.93,182,169,176.16,169,162.28V112.9c0-13.61,1.87-21.61,12.55-30.69l9.07-7.74c12-10.41,17.89-10.68,28.3-10.68H242.4c12.54,0,18.42,1.34,28.29,9.35l10.41,8.27c11.74,9.34,13.34,19.22,13.34,33.36v20.56c0,14.68-2.4,18.95-17.61,18.95H194.62v8.54c0,2.4,1.87,5.6,3.73,7.47l6.41,6.94c3.47,3.74,8.54,4.81,17.09,4.81h55c8.54,0,17.61,1.87,17.61,12.81,0,11.21-10.14,12.81-17.61,12.81Zm-21.35-79h74.2V113.17c0-4.8.27-8.81-3.2-12l-9.88-8.54c-3.2-2.94-8.27-3.2-12.28-3.2H221.85c-4.81,0-8.82,0-13.08,2.93L200,98.76c-3.21,2.4-5.34,4.27-5.34,11.74Z" />
                  <path class="cls-1"
                    d="M352.8,82.74c0-9.34,1.07-18.95,12.81-18.95,10.14,0,12.81,8.54,12.81,17.08V91.29l20-16.55c7.21-5.88,14.68-10.95,22.69-10.95h20.55c9.88,0,18.15,3.21,25.09,10.14,8.54,8.55,11.48,17.36,11.48,29.1,0,8.81-1.6,17.88-12.54,17.88-9.35,0-13.08-7.47-13.08-15.75,0-4.27,0-7.47-2.67-11.21-3.21-4.53-9.35-4.53-15.48-4.53H427c-4.8,0-8.27,2.4-12.81,6.14l-35.77,29.62V190c0,8.81-1.86,17.62-12.81,17.62S352.8,198.85,352.8,190Z" />
                  <path class="cls-1"
                    d="M591,182h19.22c8.54,0,17.61,1.87,17.61,12.81,0,11.21-10.14,12.81-17.61,12.81H545c-7.47,0-17.62-1.6-17.62-12.81,0-10.94,9.08-12.81,17.62-12.81h20.29V89.42H545c-7.47,0-17.62-1.6-17.62-12.81,0-11,9.08-12.82,17.62-12.82h27c14.68,0,19,2.67,19,17.89ZM552.25,12c0-9.07,5.07-12,12-12h14.68c6.94,0,12,2.94,12,12V24.29c0,9.07-5.07,12-12,12H564.26c-6.94,0-12-2.94-12-12Z" />
                  <path class="cls-1"
                    d="M692.91,89.15H685.7c-7.47,0-17.62-1.6-17.62-12.81,0-11,9.08-12.82,17.62-12.82h7.21V60.05c0-14.68.8-25.35,12.27-36.56,12.28-11.75,23.76-12.81,39.51-12.81H762c8.81,0,18.68.8,18.68,12.81,0,10.94-8.81,12.81-17.61,12.81h-20c-17.35,0-24.56,3.47-24.56,22.15v5.07h22.69c8.54,0,17.61,1.87,17.61,12.82,0,11.21-10.14,12.81-17.61,12.81H718.53V189.78c0,8.8-1.87,17.61-12.81,17.61s-12.81-8.81-12.81-17.61Z" />
                  <path class="cls-1"
                    d="M876.17,182h19.21c8.54,0,17.62,1.87,17.62,12.81,0,11.21-10.14,12.81-17.62,12.81H830.26c-7.48,0-17.62-1.6-17.62-12.81,0-10.94,9.08-12.81,17.62-12.81h20.28V89.42H830.26c-7.48,0-17.62-1.6-17.62-12.81,0-11,9.08-12.82,17.62-12.82h27c14.68,0,18.95,2.67,18.95,17.89ZM837.46,12c0-9.07,5.08-12,12-12h14.68c6.94,0,12,2.94,12,12V24.29c0,9.07-5.07,12-12,12H849.48c-6.94,0-12-2.94-12-12Z" />
                  <path class="cls-1"
                    d="M1012.14,135.86,972.9,89.42c-2.93-3.74-6.13-7.21-6.13-12,0-7.2,5.6-13.61,12.81-13.61,7.74,0,12.81,8,17.35,13.61l32.56,40,32.56-40c4.54-5.6,9.61-13.61,17.36-13.61,7.2,0,12.81,6.41,12.81,13.61,0,4.81-3.2,8.28-6.14,12l-39.24,46.44,39.24,46.44c2.94,3.74,6.14,7.21,6.14,12,0,7.21-5.61,13.35-12.81,13.35-7.75,0-12.82-7.74-17.36-13.35l-32.56-40-32.56,40c-4.54,5.61-9.61,13.35-17.35,13.35-7.21,0-12.81-6.14-12.81-13.35,0-4.8,3.2-8.27,6.13-12Z" />
                  <path class="cls-2"
                    d="M101.88,0C92.27,0,88.54,4.8,88.54,13.35v42.7L49.3,158l-23-59.9a11.23,11.23,0,0,0-10.48-7.19H11.24A11.22,11.22,0,0,0,.79,106.24L36,195.91c2.66,6.68,5.07,11.75,13.34,11.75S60,202.59,62.65,195.91l51-130a27.56,27.56,0,0,0,1.6-10.41V13.35C115.23,4.8,111.49,0,101.88,0Z" />
                </g>
              </g>
            </svg>
          </div>
          <!-- /.Footer Logo -->
        </div>
      </div>
      <hr class="bg-gray-800 my-3" />
      <div class="row mb-8">
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <div class="text-white-50 mb-3">
            <!-- widget text -->
            <p>
              Nous sommes une plateforme où les clients peuvent saisir toutes leurs
              informations. VERIFIX vérifiera les informations avec les données existantes
              et fournira une réponse dans les 72 heures. Le bureau de VERIFIX est
              également disponible pour ceux qui ont besoin d'aide.
            </p>
            <p>
              <strong class="text-success">Pourquoi ?<br /> </strong> Pour assurer la
              transparence aux institutions avant de conclure un partenariat, ou de
              fournir un crédit, etc. Les institutions peuvent référer leurs clients à
              VERIFIX afin d'obtenir un rapport de diligence raisonnable.
            </p>
            <p>
              <strong class="text-success">Comment ?</strong><br />
              Avec plus de 2000 données existantes qui sont vérifiées et toutes les
              institutions disposeront d'un point de contact pour fournir des
              informations. VERIFIX répondra dans les 72 heures et le code QR sera
              disponible pendant 5 jours pour être partagé avec l'institution.
            </p>
          </div>
          <!-- /.widget text -->
        </div>
        <div class="col-xl-6">
          <div class="row mt-6">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
              <div class="d-flex">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                  class="bi bi-geo-alt text-white mt-1" viewBox="0 0 16 16">
                  <path
                    d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                  <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                </svg>

                <div class="ms-3">
                  03 Bismark Q. Golf C.Gombe Ref. Terrain maman yemo Immeuble Startup
                  1<sup>er</sup> niveau
                </div>
              </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mt-3 mt-md-0">
              <div class="d-flex">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                  class="bi bi-telephone text-white mt-1" viewBox="0 0 16 16">
                  <path
                    d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                </svg>

                <div class="ms-3 fs-3">+(243) 999618704</div>
              </div>
              <div class="d-flex mt-3">
                <i class="bi bi-envelope"></i>
                <div class="ms-3 fs-3">contact@verifixs.com</div>
              </div>
              <div class="d-flex mt-3">
                <i class="bi bi-envelope" style="display:none;"></i>
                <div class="ms-3 fs-3" style="display:none;">info@verifixs.com</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
          <p class="fs-6 text-muted">© Copyright 2022 | RT Group SARL</p>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-md-end">
          <p class="fs-6 text-muted">
            <a href="#" @click.prevent="$emit('onShowPrivacy')" class="text-inherit">Politique de confidentialité !</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "footerLayout",
};
</script>

<style>
.cls-1 {
  fill: #f4f6f9;
}

.cls-2 {
  fill: #33cc7c;
}
</style>
